export const FRENCH_TRANSLATIONS_SW_THERMOMETRY = {
  title: 'Surveillance de températures',
  period: {
    hour: 'Heure',
    day: 'Jour',
    week: 'Semaine',
    month: 'Mois',
    year: 'Année'
  },
  list_equipments: 'Liste équipements',
  equipment_detail: 'Détail équipement',
  search: 'Tous les équipements, services, groupes...',
  sort: 'Trier par',
  identify: 'S\'identifier',
  acquit_identification: 'Identification pour acquittement',
  acquit: 'Acquitter',
  save: 'Sauvegarder',
  alert_infos: 'Informations alerte',
  current: 'Actuelle',
  mean: 'Moyenne',
  thresholds: 'Seuils',
  statement: 'Relevé',
  door_state: 'État de la porte',
  indicators: 'Indicateurs',
  last_open: 'ouvertures sur les dernières 24h',
  opened_mean: 'd\'ouverture en moyenne',
  alert_period: 'sur la période',
  alertStartDate: 'Début alerte',
  alertType: 'Type',
  maxTemperature: 'Température maximum',
  alertDuration: 'Durée alerte',
  alertEndDate: 'Fin alerte',
  type: 'Type',
  date: 'Date',
  temperature: 'Température',
  sensor: {
    title: 'Capteur',
    identifier: 'Identifiant',
    type: 'Type de capteur',
    register_range: 'Intervalle d\'enregistrement',
    info_range: 'Intervalle d\'envoi des informations',
    calibration: 'Étalonnage',
    made: "Fait le ",
    adjustmentCurve: "Courbe d'ajustement "
  },
  history: {
    title: 'Historique d\'événements',
    search: 'Toutes les alertes',
    alerts: 'Alertes',
    events: 'pas d\'événements | 1 événement | {count} événements',
    noEvents: 'Pas d\'événements',
    identifier: 'Intervenant',
    filter: 'Filtrer par',
    eventTypes: {
      threshold_overrun: "Dépassement de seuil de température",
      comment: "Commentaire",
      acquittment: "Acquittement",
      open_close: "Porte ouverte"
    },
    eventModal: {
      edit: 'Éditer',
      acquitBy: 'Acquitté par'
    },
    info: 'Informations complémentaires',
    acquitted: 'Acquittée',
    not_acquitted: 'Non Acquittée',
    during: 'pendant',
    since: 'depuis',
    max_temp: 'Température maximale',
    min_temp: 'Température minimale',
    day: 'jour',
    reason: 'Cause',
    action: 'Action'
  },
  information: {
    title: 'Informations',
    location: 'Localisation',
    enclosure_type: 'Type d\'enceinte',
    comment: 'Commentaire',
    edit: 'Modifier'
  },
  register: 'Enregistrer',
  min_threshold: 'Seuil minimum',
  max_threshold: 'Seuil maximum',
  thresholds_update: 'Modifications des seuils',
  nominal_temperature: 'Température\nnominale',
  alerts_update: 'Modifications des alertes',
  hour: 'heure',
  hours: 'heures',
  minute: 'minute',
  minutes: 'minutes',
  second: 'seconde',
  seconds: 'secondes',
  times: {
    hour: 'heure',
    hours: 'heures',
    minute: 'minute',
    minutes: 'minutes',
    second: 'seconde',
    seconds: 'secondes',
  },
  short_times: {
    hour: 'h',
    minute: 'min',
    second: 's',
  },
  both_thresholds: 'Veuillez remplir les deux seuils pour valider cette modification.',
  error: {
    min_greater_max: 'Le seuil minimum doit être inférieur au seuil maximum'
  },
  nominalValue: 'Température nominale',
  alert_info: 'L\'alerte se déclenche lorsque la température dépasse un seuil',
  graph: {
    no_data: 'Aucunes données de {type} pour les dates sélectionnées.',
    downloadData: 'Télécharger les données',
    downloadImage: 'Téléchargement PNG',
    downloadPDF: 'Téléchargement PDF',
    resetZoom: 'Reset zoom'
  },
  min_registered_temperature: 'Température minimale enregistrée',
  max_registered_temperature: 'Température maximale enregistrée',
  list: {
    name: 'Nom',
    alert_state: 'État d\'alerte',
    family: 'Famille',
    temperature: 'Température',
    service: 'Service',
    date: 'Date',
    noEquipments: 'Pas d\'équipements'
  },
  dataTypes: {
    temperature: 'Température',
    pressure: 'Pression',
    humidity: 'Humidité',
    contact: 'Porte',
    open_close: 'Porte',
    co2: 'CO2',
    touch: 'Tactile',
    proximity: 'Porte'
  },
  display: 'Affichage',
  close: 'Fermée',
  open: 'Ouverte'
};
